import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { AbstractModal } from '@component/Modal';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
let ActivationLinkSentModal = class ActivationLinkSentModal extends AbstractModal {
    /**
     * Handles the `@click` event on the "log in" button.
     */
    onLogInBtnClick() {
        if (!this.payload.shouldRedirect) {
            this.close();
        }
        else {
            this.$router.push('/');
            this.close();
        }
    }
};
ActivationLinkSentModal = __decorate([
    Component({ name: 'ActivationLinkSentModal' })
], ActivationLinkSentModal);
export { ActivationLinkSentModal };
export default ActivationLinkSentModal;
